.mini{
  position: fixed;
  top:0;
  right: 30;
  animation: disappear 4s ease-in-out 0s 1 normal forwards;
}

@keyframes disappear{
  0%{
    opacity: 100%;
    color:white
  }
  50%{
    color: rbga(0,0,255,0.5);
  }
  100%{
    color: rgba(0,0,255,0);
    opacity: 0%;
  }
}