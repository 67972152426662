.loading {
  background-color: rgba(255,255,255,1);
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  position: fixed;

}

#true {
  animation: fade 0s linear;
}

#false {
  animation: fade 0.5s reverse ease forwards;
}

@keyframes fade {
  0% {background-color: rgba(255,255,255,0);}
  100% { background-color: rgba(255,255,255,1);}
}