.toast{
  width: 100%;
  min-height: 100%;
  font-size: 1vw;
  position: relative;
  font-family: 'Bahnschrift';
  filter: drop-shadow(4px 4px 5px black);
}

#stress{
  font-size: 10vw;
  padding-top: 30vh;
  padding-bottom: 1vh;
}